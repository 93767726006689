import { ExpandedMessage } from '@portal/query';

interface UploadOptions {
  readonly headers?: Record<string, string>;
  onError?(error: Error): void;
  onSuccess?(response: Response): void;
  onSettled?(): void;
  onProgress?(progress: number): void;
  onStart?(): void;
  message?: ExpandedMessage;
}

type ActiveUpload = {
  controller: AbortController;
  file: File;
  url: string;
  options: UploadOptions;
};

const specialCharsMap: Record<string, string> = {
  '%20': ' ',
  '%25': '%',
  '%2C': ',',
  '%2F': '/',
  '%3F': '?',
  '%3A': ':',
  '%40': '@',
  '%26': '&',
  '%3D': '=',
  '%2B': '+',
  '%24': '$',
  '%23': '#'
};

export const decodeFileName = (str: string): string => {
  return Object.entries(specialCharsMap).reduce((acc, [key, value]) => {
    return acc.replaceAll(key, value);
  }, str);
};

export const UploadManager = (() => {
  const activeUploads: Record<string, ActiveUpload> = {};
  const canceledUploads: Record<string, { file: File; url: string; options: UploadOptions }> = {};

  return {
    async upload(
      url: string,
      file: File,
      options: UploadOptions = {}
    ) {
      const controller = new AbortController();
      const signal = controller.signal;
      const fileId = `${file.name}-${file.lastModified}`;
      activeUploads[fileId] = { controller, file, url, options };

      try {
        options.onStart?.();

        const body = new File([file], file.name, {
          type: file.type
        });

        const response = await fetch(url, {
          method: 'PUT',
          body,
          headers: options.headers,
          signal,
        });

        if (response.ok) {
          options.onSuccess?.(response);
        } else {
          throw new Error(`Upload failed: ${response.statusText}`);
        }
      } catch (error) {
        if (signal.aborted) {
          canceledUploads[fileId] = { file, url, options };
        } else {
          options.onError?.(error as Error);
          console.error('Upload error:', error);
        }
      }
    },

    cancelUpload(fileId: string) {
      if (activeUploads[fileId]) {
        const { file, url, options } = activeUploads[fileId];
        activeUploads[fileId].controller.abort();
        delete activeUploads[fileId];

        canceledUploads[fileId] = { file, url, options };
      }
    },

    retryUpload(fileId: string, callback: () => void) {
      if (canceledUploads[decodeFileName(fileId)]) {
        delete canceledUploads[decodeFileName(fileId)];

        callback();
      } else {
        console.error('No canceled upload found for:', decodeFileName(fileId));
      }
    },

    getActiveUploads() {
      return Object.entries(activeUploads).map(([fileId, { file }]) => ({ fileId, file }));
    },

    getCanceledUploads() {
      return Object.entries(canceledUploads).map(([fileId, { file }]) => ({ fileId, file }));
    },

    isCanceled(fileId: string) {
      return !!canceledUploads[decodeFileName(fileId)];
    },

    isActiveUpload(fileId: string) {
      return !!activeUploads[decodeFileName(fileId)];
    },

    getUpload(fileId: string) {
      const upload = canceledUploads[decodeFileName(fileId)] || activeUploads[decodeFileName(fileId)];
      return upload ? {
        file: upload.file,
        message: upload.options.message || null,
      } : null;
    },

    setDone(fileId: string) {
      delete activeUploads[decodeFileName(fileId)];
    }
  };
})();
