import { type InfiniteData } from '@tanstack/react-query';
import {
  adjust,
  append,
  curry,
  defaultTo,
  either,
  equals,
  findIndex,
  isNil,
  lensPath,
  over,
  propEq,
  reject,
} from 'ramda';
import { Page_Message_ } from '@portal/chat-sdk';
import { ExpandedMessage } from './chat';

export type Query = Record<string, string | number | boolean>;

function isValidQuery(query: unknown): query is Query {
  return typeof query === 'object' && query !== null && !Array.isArray(query);
}

/**
 * Converts an object into a query string
 * @param query
 * @returns
 */
function createQueryString<T extends Query>(query: T | unknown) {
  if (!isValidQuery(query)) {
    return '';
  }
  return Object.entries(query)
    .reduce((search, [key, value]) => {
      search.set(key, value.toString());
      return search;
    }, new URLSearchParams())
    .toString();
}

interface URLOptions {
  pathname: string;
  query?: Query;
}

export function url<T extends URLOptions>({ query, pathname }: T) {
  const base = new URL(process.env.NEXT_PUBLIC_SITE_URL);
  base.pathname = pathname;
  base.search = createQueryString(query);
  return base.toString();
}

interface RequestObject {
  pathname: string;
  query?: Query | undefined;
}

export async function request<R, S extends RequestObject = RequestObject>(config: S, options?: RequestInit) {
  const response = await fetch(url(config), options);
  if (!response.ok) {
    throw new Error('Invalid response');
  }
  return response.json() as Promise<R>;
}

export type ResponseWithStream = Response & { body: ReadableStream<Uint8Array> };

function isStreamableResponse(response: Response): response is ResponseWithStream {
  return response.body instanceof ReadableStream;
}

export async function stream<S extends RequestObject = RequestObject>(config: S, options?: RequestInit) {
  const response = await fetch(url(config), options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  if (!isStreamableResponse(response)) {
    throw new Error('Response does not have a valid readable body');
  }
  return response;
}

function parse(line: string = '') {
  try {
    return JSON.parse(line);
  } catch (err) {
    return '';
  }
}

export interface ToolCallChunk {
  type: 'tool_call';
  tool_calls: {
    id: string;
    function: {
      arguments: string;
      name: string;
    };
    type: string;
    query: string;
  }[];
}

export interface TextChunk {
  type: 'text';
  text: string;
}

export interface KeepAliveChunk {
  type: 'keep_alive';
}

export interface ToolChunk {
  type: 'tool';
  text: string;
  tool_call_id: string;
}

export type ChatChunk = TextChunk | ToolCallChunk | ToolChunk | KeepAliveChunk;

export function isChunkOfType<T extends ChatChunk>(chunk: ChatChunk, type: T['type']): chunk is T {
  return chunk.type === type;
}

export function toBuffered() {
  let buffer = '';

  return new TransformStream<string, string>({
    transform(chunk, controller) {
      const chunkWithBuffer = buffer + chunk;
      const hasStart = chunkWithBuffer.startsWith(`{`);
      const hasEnd = chunkWithBuffer.lastIndexOf(`}\n`) !== -1;
      const isFinishedChunk = hasStart && hasEnd;

      if (isFinishedChunk) {
        controller.enqueue(chunkWithBuffer);

        if (buffer) {
          buffer = '';
        }

        return;
      }

      buffer = chunk;
    },
  });
}

export function toJson() {
  return new TransformStream<string, ChatChunk>({
    transform(chunk, controller) {
      const lines = chunk.split(/\n/).filter(Boolean).map(parse);
      for (const line of lines) {
        controller.enqueue(line);
      }
    },
  });
}

/**
 * Represents the generic structure of a paginated response from fastapi
 */
export interface Paginated<T> {
  items: T[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

type Dict = Record<PropertyKey, any>;

interface ItemWithId extends Dict {
  id: string;
}

export interface Updater<T> {
  (res: T): T;
  (a: T, b: T): T;
}

type Predicate<T> = (item: T) => boolean;

interface Upsert {
  <T>(predicate: Predicate<T>, updater: Updater<T>, item: T, data: T[]): T[];
  <T>(predicate: Predicate<T>): (updater: Updater<T>, item: T, data: T[]) => T[];
  <T>(predicate: Predicate<T>, updater: Updater<T>): (item: T, data: T[]) => T[];
  <T>(predicate: Predicate<T>, updater: Updater<T>, item: T): (data: T[]) => T[];
}

/**
 * Tries to find the item in a list of data
 * if it finds it, it will update the item at that index with the `updater`
 * if it doesn't find it, it will append the item to the end of the list
 * @param predicate - A function that returns true if the item is found
 * @param updater - when updating, this function will be called with the incoming item and the current item
 * @param item - the item to upsert
 * @param data - the list to upsert into
 * @example
 * ```typescript
 * const mangaCollection = [
 *   {
 *     id: 1,
 *     title: "One Piece",
 *     volume: 1,
 *     title: 'Romance Dawn',
 *     author: 'Eiichiro Oda'
 *   },
 * ];
 *
 * const newVolume = {
 *   id: 2,
 *   title: "One Piece",
 *   volume: 2,
 *   title: 'Buggy the Clown',
 *   author: 'Eiichiro Oda',
 * };
 *
 * // This will add the new volume to the collection
 * upsert(propEq('id', newVolume.id), mergeDeepLeft, newVolume, mangaCollection);
 *
 * // Since the volume is already in the collection, it will update the volume
 * upsert(propEq('id', newVolume.id), mergeDeepLeft, newVolume, mangaCollection);
 * ```
 */
export const upsert = curry(function upsert<T extends ItemWithId>(
  predicate: (item: T) => boolean,
  updater: Updater<T>,
  item: T,
  data: T[],
) {
  const index = findIndex<T>(predicate, data);
  return index < 0 ? append<T>(item, data) : adjust<T>(index, (current) => updater(item, current), data);
}) as Upsert;

export function defaultPage<T>(data: InfiniteData<Paginated<T>, number> | undefined | null) {
  return defaultTo<InfiniteData<Paginated<T>, number>, InfiniteData<Paginated<T>, number> | undefined | null>(
    {
      pages: [
        {
          items: [],
          page: 1,
          pages: 1,
          size: 50,
          total: 0,
        },
      ],
      pageParams: [1],
    },
    data,
  );
}

interface OptimisticUpsert {
  <T>(updater: Updater<T>, item: T, data: InfiniteData<Paginated<T>, number> | undefined): InfiniteData<
    Paginated<T>,
    number
  >;
  <T>(updater: Updater<T>): (
    item: T,
    data: InfiniteData<Paginated<T>, number> | undefined,
  ) => InfiniteData<Paginated<T>, number>;
  <T>(updater: Updater<T>, item: T): (
    data: InfiniteData<Paginated<T>, number> | undefined,
  ) => InfiniteData<Paginated<T>, number>;
}

/**
 * Used to optimistically add an item to the end of an infinite query
 * @example
 * ```typescript
 * queryClient.setQueryData(['chat', 'messages'], (previous) => {
 *   const message = {
 *     id: v4(),
 *     content: 'Hello, World!',
 *   };
 *
 *   return optimisticInfiniteUpsert(
 *     propEq('id', message.id),
 *     mergeLeft(message),
 *     message,
 *     previous,
 *   );
 * });
 * ```
 */
export const optimisticInfiniteQueryUpsert = curry(function optimistic<
  T extends ItemWithId,
  P extends InfiniteData<Paginated<T>, number> = InfiniteData<Paginated<T>, number>,
>(updater: Updater<T>, item: T, data: P | undefined): InfiniteData<Paginated<T>, number> {
  const lastPage = Math.max((data?.pages?.length ?? 0) - 1, 0);
  return over(lensPath(['pages', lastPage, 'items']), upsert(propEq(item.id, 'id'), updater, item), defaultPage(data));
}) as OptimisticUpsert;

type RejectEmpty<T extends object> = {
  [K in keyof T]: T[K] extends undefined | null ? never : T[K];
};

const isNullish = either(isNil, equals(undefined));
export const filterEmptyKeys = reject(isNullish) as {
  <T extends object>(obj: T): RejectEmpty<T>;
};
